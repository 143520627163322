var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(" "+_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"outlined":"","clearable":"","dense":"","items":_vm.nomencladorItems,"item-text":"value","item-value":"id","autocomplete":"on","label":"Nomenclador","rules":_vm.rules.required},model:{value:(_vm.nomencladorSelected),callback:function ($$v) {_vm.nomencladorSelected=$$v},expression:"nomencladorSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Código desde","outlined":"","clearable":"","dense":"","rules":_vm.nomencladorHasta != null
                  ? _vm.rules.requiredAcceptZero.concat(
                      _vm.rules.positiveNumber,
                      [_vm.rules.maxLength(_vm.nomencladorDesde, 8)],
                      parseInt(_vm.nomencladorDesde) <=
                        parseInt(_vm.nomencladorHasta) || 'Rango invalido'
                    )
                  : []},model:{value:(_vm.nomencladorDesde),callback:function ($$v) {_vm.nomencladorDesde=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nomencladorDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Código hasta","outlined":"","clearable":"","dense":"","rules":_vm.nomencladorDesde != null && _vm.nomencladorHasta != null
                  ? _vm.rules.requiredAcceptZero.concat(
                      _vm.rules.positiveNumber,
                      [_vm.rules.maxLength(_vm.nomencladorHasta, 8)],
                      Number(_vm.nomencladorDesde) <= Number(_vm.nomencladorHasta) ||
                        'Rango invalido'
                    )
                  : []},model:{value:(_vm.nomencladorHasta),callback:function ($$v) {_vm.nomencladorHasta=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nomencladorHasta"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha vigencia desde","append-icon":_vm.calendarIcon,"outlined":"","clearable":"","dense":"","hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaVigenciaDesdeSelected)) <=
                        new Date(
                          _vm.parseDateToIso(_vm.fechaVigenciaHastaSelected)
                        ) || 'Formato incorrecto'
                    )},on:{"blur":function($event){_vm.fechaVigenciaDesde = _vm.parseDateToIso(
                      _vm.fechaVigenciaDesdeSelected
                    )}},model:{value:(_vm.fechaVigenciaDesdeSelected),callback:function ($$v) {_vm.fechaVigenciaDesdeSelected=$$v},expression:"fechaVigenciaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaVigenciaDesdeSelected = _vm.formatDate(_vm.fechaVigenciaDesde)},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaVigenciaDesde),callback:function ($$v) {_vm.fechaVigenciaDesde=$$v},expression:"fechaVigenciaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha vigencia hasta","append-icon":_vm.calendarIcon,"outlined":"","clearable":"","dense":"","hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaVigenciaDesdeSelected)) <=
                        new Date(
                          _vm.parseDateToIso(_vm.fechaVigenciaHastaSelected)
                        ) || 'Formato incorrecto'
                    )},on:{"blur":function($event){_vm.fechaVigenciaHasta = _vm.parseDateToIso(
                      _vm.fechaVigenciaHastaSelected
                    )}},model:{value:(_vm.fechaVigenciaHastaSelected),callback:function ($$v) {_vm.fechaVigenciaHastaSelected=$$v},expression:"fechaVigenciaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaVigenciaHastaSelected = _vm.formatDate(_vm.fechaVigenciaHasta)},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaVigenciaHasta),callback:function ($$v) {_vm.fechaVigenciaHasta=$$v},expression:"fechaVigenciaHasta"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text"> {{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                outlined
                clearable
                dense
                :items="nomencladorItems"
                item-text="value"
                item-value="id"
                autocomplete="on"
                label="Nomenclador"
                v-model="nomencladorSelected"
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="nomencladorDesde"
                label="Código desde"
                outlined
                clearable
                dense
                :rules="
                  nomencladorHasta != null
                    ? rules.requiredAcceptZero.concat(
                        rules.positiveNumber,
                        [rules.maxLength(nomencladorDesde, 8)],
                        parseInt(nomencladorDesde) <=
                          parseInt(nomencladorHasta) || 'Rango invalido'
                      )
                    : []
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="nomencladorHasta"
                label="Código hasta"
                outlined
                clearable
                dense
                :rules="
                  nomencladorDesde != null && nomencladorHasta != null
                    ? rules.requiredAcceptZero.concat(
                        rules.positiveNumber,
                        [rules.maxLength(nomencladorHasta, 8)],
                        Number(nomencladorDesde) <= Number(nomencladorHasta) ||
                          'Rango invalido'
                      )
                    : []
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaVigenciaDesdeSelected"
                    label="Fecha vigencia desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    clearable
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="
                      fechaVigenciaDesde = parseDateToIso(
                        fechaVigenciaDesdeSelected
                      )
                    "
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaVigenciaDesdeSelected)) <=
                          new Date(
                            parseDateToIso(fechaVigenciaHastaSelected)
                          ) || 'Formato incorrecto'
                      )
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVigenciaDesde"
                  no-title
                  @change="
                    fechaVigenciaDesdeSelected = formatDate(fechaVigenciaDesde)
                  "
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaVigenciaHastaSelected"
                    label="Fecha vigencia hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    clearable
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="
                      fechaVigenciaHasta = parseDateToIso(
                        fechaVigenciaHastaSelected
                      )
                    "
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaVigenciaDesdeSelected)) <=
                          new Date(
                            parseDateToIso(fechaVigenciaHastaSelected)
                          ) || 'Formato incorrecto'
                      )
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVigenciaHasta"
                  no-title
                  @change="
                    fechaVigenciaHastaSelected = formatDate(fechaVigenciaHasta)
                  "
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditCodigoPrescrible",
  props: {
    itemToEdit: {
      type: Object,
      required: false
    },
    tipoPrestadorId: {
      type: Number,
      required: true
    }
  },
  directives: { mask },
  data: vm => ({
    rules: rules,
    title: enums.titles.FORMAS_PAGO,
    formEditTitle: "Editar código habilitado",
    calendarIcon: enums.icons.CALENDAR,
    nomencladorItems: [],
    nomencladorSelected: null,
    nomencladorDesde: null,
    nomencladorHasta: null,
    menu1: null,
    menu2: null,
    isFormValid: false,
    fechaVigenciaDesde: new Date(
      new Date().valueOf() - 1000 * 60 * 60 * 24 * 10
    )
      .toISOString()
      .substr(0, 10),
    fechaVigenciaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
        .toISOString()
        .substr(0, 10)
    ),
    fechaVigenciaHasta: new Date().toISOString().substr(0, 10),
    fechaVigenciaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    )
  }),
  created() {
    this.setSelects();
    if (this.itemToEdit != null) {
      this.isFormValid = true;
      this.setCodigosPresc(this.itemToEdit);
    } else {
      this.formEditTitle = "Nuevo código a habilitar";
    }
  },
  methods: {
    ...mapActions({
      getNomencladores: "afiliaciones/getNomencladores",
      setAlert: "user/setAlert",
      postCodigoPrescribible: "prestadores/postCodigoPrescribible"
    }),
    async setSelects() {
      const nomencladores = await this.getNomencladores();
      this.nomencladorItems = nomencladores;
    },
    setCodigosPresc(item) {
      this.nomencladorSelected = item.nomencladorId;
      this.nomencladorDesde = item.nomencladorCodDesde;
      this.nomencladorHasta = item.nomencladorCodHasta;
      this.fechaVigenciaDesdeSelected = item.vigenciaDesde;
      this.fechaVigenciaDesde = this.parseDateToIso(item.vigenciaDesde);
      this.fechaVigenciaHastaSelected = item.vigenciaHasta;
      this.fechaVigenciaHasta = this.parseDateToIso(item.vigenciaHasta);
    },
    async saveEdit() {
      this.isFormValid = false;
      const data = {
        codPreId: this.itemToEdit == null ? 0 : this.itemToEdit.codPreId,
        tpId: this.tipoPrestadorId,
        nomencladorId: this.nomencladorSelected,
        nomencladorCodDesde: this.nomencladorDesde,
        nomencladorCodHasta: this.nomencladorHasta,
        vigenciaDesde: this.fechaVigenciaDesde,
        vigenciaHasta: this.fechaVigenciaHasta
      };
      try {
        const res = await this.postCodigoPrescribible(data);
        if (res.status === 200) {
          this.isFormValid = true;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
        }
      } catch {
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }
  }
};
</script>

<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="nombreTipoPrestador"
                label="Nombre"
                outlined
                clearable
                dense
                :rules="
                  rules.required.concat(
                    [rules.maxLength(nombreTipoPrestador, 50)],
                    [rules.requiredTrim(nombreTipoPrestador)]
                  )
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model.trim="codigoInterno"
                label="Código interno"
                outlined
                clearable
                dense
                :rules="[rules.maxLength(codigoInterno, 10)]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model.trim="codigoAdesfa"
                label="Código ADESFA"
                outlined
                clearable
                dense
                :rules="[rules.maxLength(codigoAdesfa, 10)]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model.trim="codigoSss"
                label="Código SSS"
                outlined
                clearable
                dense
                :rules="
                  rules.positiveNumber.concat(rules.maxLength(codigoSss, 3))
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              <v-switch
                class="my-0"
                v-model="esOdontologo"
                :disabled="esFarmacia"
                label="Es odontólogo"
              ></v-switch>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-switch
                class="my-0"
                v-model="esFarmacia"
                :disabled="esOdontologo"
                label="Es farmacia"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditTipoPrestadores",
  props: {
    tipoPrestadorItem: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    formEditTitle: "Editar tipo de prestador",
    rules: rules,
    isFormValid: false,
    //campos nuevo/edit
    nombreTipoPrestador: null,
    codigoInterno: null,
    codigoAdesfa: null,
    codigoSss: null,
    codigoPresc: null,
    origenes: null,
    prestadores: null,
    esOdontologo: false,
    esFarmacia: false
  }),
  created() {
    if (this.tipoPrestadorItem != null) {
      this.setTipoPrestador(this.tipoPrestadorItem);
    } else {
      this.formEditTitle = "Nuevo tipo de prestador";
    }
  },
  methods: {
    ...mapActions({
      saveTipoPrestador: "prestadores/saveTipoPrestador",
      setAlert: "user/setAlert"
    }),
    setTipoPrestador(item) {
      this.nombreTipoPrestador = item.tpNom;
      this.codigoAdesfa = item.codAdesfa;
      this.codigoSss = item.sssCodigo;
      this.esFarmacia = item.esFarmacia;
      this.esOdontologo = item.esOdontologo;
      this.codigoInterno = item.tpCod;
    },
    async saveEdit() {
      this.isFormValid = false;
      const data = {
        tpId: this.tipoPrestadorItem?.tpId,
        tpCod: this.codigoInterno,
        tpNom: this.nombreTipoPrestador,
        codAdesfa: this.codigoAdesfa,
        sssCodigo: this.codigoSss,
        esFarmacia: this.esFarmacia,
        esOdontologo: this.esOdontologo
      };
      const res = await this.saveTipoPrestador(data);
      if (res.status === 200) {
        this.isFormValid = true;
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>

<template>
  <div>
    <v-card>
      <v-container>
        <v-card-title class="p-0">
          <span class="pl-1 primary--text"
            >Códigos habilitados a prescribir: {{ tipoPrestadorNom }}</span
          >
        </v-card-title>
        <v-card outlined>
          <v-container class="py-0 pr-0">
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-card-text class="py-0">
                <v-form
                  v-show="showFilters"
                  v-model="isFormValid"
                  ref="filters-form"
                  id="filters-form"
                  @submit.prevent="applyFilters()"
                >
                  <v-row class="pr-4 pl-4" justify="end">
                    <!-- fecha vigenicia -->
                    <v-col cols="4" class="py-0">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaVigenciaSelected"
                            label="Fecha vigencia"
                            :append-icon="calendarIcon"
                            v-bind="attrs"
                            outlined
                            clearable
                            dense
                            hint="Formato DD/MM/AAAA"
                            v-mask="'##/##/####'"
                            onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                            @blur="
                              fechaVigencia = parseDateToIso(
                                fechaVigenciaSelected
                              )
                            "
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaVigencia"
                          no-title
                          @change="
                            fechaVigenciaSelected = formatDate(fechaVigencia)
                          "
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="8" class="to-right pr-0" align="end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            size="28"
                            @click="resetForm"
                          >
                            {{ clearFiltersIcon }}
                          </v-icon>
                        </template>
                        <span>Limpiar filtros</span>
                      </v-tooltip>
                      <v-btn
                        class="to-right"
                        color="primary"
                        elevation="2"
                        small
                        type="submit"
                        form="filters-form"
                      >
                        Aplicar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-expand-transition>
            <v-spacer></v-spacer>
          </v-container>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="itemsCodigosPresc"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          :expanded.sync="expanded"
          show-expand
          item-key="codPreId"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar código habilitado</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete && item.fPagoId != 1">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteCodPresc(item.codPreId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar código habilitado</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fechaAlta }}
              <strong style="padding-left:50px;">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left:50px;">Fecha modificación:</strong>
              {{ item.fechaModi }}
              <strong style="padding-left:50px;">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
        </v-data-table>
        <v-card-actions class="pr-0">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal"> Cerrar </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <v-dialog
      v-if="openModalCodigoPresc"
      v-model="openModalCodigoPresc"
      max-width="40%"
      @keydown.esc="closeModalCodigoPresc"
      persistent
    >
      <EditCodigoHabilitadoTipoPrestador
        :itemToEdit="itemToEdit"
        :tipoPrestadorId="tipoPrestadorId"
        @closeAndReload="closeModalCodigoPresc"
      ></EditCodigoHabilitadoTipoPrestador
    ></v-dialog>
    <DeleteDialog
      :isLoading="loadBtn"
      :titleProp="titleDelete"
      :maxWidth="'40%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import EditCodigoHabilitadoTipoPrestador from "@/components/modules/prestadores/EditCodigoHabilitadoTipoPrestador.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  name: "CodigoPrescribibleTipoPrestador",
  components: {
    FiltersSelected,
    EditCodigoHabilitadoTipoPrestador,
    DeleteDialog
  },
  props: {
    tipoPrestadorId: {
      type: Number,
      required: false
    },
    tipoPrestadorNom: {
      type: String,
      required: false
    }
  },
  directives: { mask },
  data: vm => ({
    allowedActions: null,
    rules: rules,
    search: "",
    optionCode: enums.webSiteOptions.CODIGOS_PRESCRIBIBLES,
    searchIcon: enums.icons.SEARCH,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    canCreate: false,
    canDelete: false,
    canEdit: false,
    isLoading: false,
    codigosPrescItems: [],
    expanded: [],
    openModalCodigoPresc: false,
    itemToEdit: null,
    itemToDelete: null,
    showDeleteModal: false,
    titleDelete: "¿Eliminar rango de códigos habilitados?",
    loadBtn: false,
    //FILTROS
    filtersApplyed: [],
    showFilters: true,
    filtersApplied: [],
    isFormValid: false,
    loading: false,
    menu1: null,
    fechaVigencia: null,
    fechaVigenciaSelected: null,
    itemsCodigosPresc: [],
    headers: [
      {
        text: "Nomenclador",
        value: "nomencladorNom",
        sortable: false
      },
      {
        text: "Código desde",
        value: "nomencladorCodDesde",
        sortable: false,
        align: "right"
      },
      {
        text: "Código hasta",
        value: "nomencladorCodHasta",
        sortable: false,
        align: "right"
      },
      {
        text: "Vigencia desde",
        value: "vigenciaDesde",
        sortable: false,
        align: "center"
      },
      {
        text: "Vigencia hasta",
        value: "vigenciaHasta",
        sortable: false,
        align: "center"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end"
      }
    ]
  }),
  created() {
    this.applyFilters();
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getCodigosPrescribibles: "prestadores/getCodigosPrescribibles",
      getPermisosCodigosPresc: "prestadores/getPermisosCodigosPresc",
      deleteCodigoPrescribible: "prestadores/deleteCodigoPrescribible",
      setAlert: "user/setAlert"
    }),
    async setPermisos() {
      const permisos = await this.getPermisosCodigosPresc(this.optionCode);
      this.canCreate = permisos.canCreate;
      this.canEdit = permisos.canEdit;
      this.canDelete = permisos.canDelete;
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaVigenciaSelected) {
        this.filtersApplied.splice(0, 0, {
          key: "fechaVigenciaSelected",
          label: "Fecha vigencia",
          model: this.fechaVigenciaSelected
        });
      } else {
        this.filtersApplied.splice(0, 0, {
          key: "fechaVigenciaSelected",
          label: "Fecha vigencia",
          model: "Todas"
        });
      }
    },
    async applyFilters() {
      this.isLoading = true;
      const filters = {
        tpId: this.tipoPrestadorId,
        fechaVigencia: this.fechaVigencia
      };
      try {
        this.showFilters = false;
        this.customizeFiltersApplied();
        const codigosPresc = await this.getCodigosPrescribibles(filters);
        this.itemsCodigosPresc = codigosPresc;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    openModal(item) {
      this.itemToEdit = item;
      this.openModalCodigoPresc = true;
    },
    deleteCodPresc(id) {
      this.itemToDelete = id;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      this.loadBtn = true;
      const response = await this.deleteCodigoPrescribible(this.itemToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
        this.loadBtn = false;
      }
    },
    closeModalCodigoPresc() {
      this.openModalCodigoPresc = false;
      this.applyFilters();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    }
  }
};
</script>
